<template>
  <!-- Container -->
  <div class="app-container">
    <WeAppLicenseBar />
    <WeLiveForm />
    <WeAppHeader v-if="header" />
    <WeContent v-bind:fluid="fluid" v-bind:wide="!fluid && wide">
      <template v-if="viewName">
        <router-view v-bind:name="viewName"></router-view>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </WeContent>
    <!-- <WeAppSupport v-if="support" /> -->
    <WeAppFooter v-if="footer" />
    <WeSetup />
  </div>
  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeAppContainer",
  props: {
    header: {
      default: "true",
    },
    footer: {
      default: "true",
    },
    support: {
      default: "true",
    },
    fluid: {
      default: "true",
    },
    wide: {
      default: "true",
    },
    "view-name": {
      default: "",
    },
  },
};
</script>

<style lang="scss">
.nav-pills .nav-link {
  color: #3f51b5;
}

.nav-pills .nav-link.active {
  background-color: #3f51b5 !important;
  box-shadow: 0px 3px 5px rgba(128, 128, 128, 0.295);
}

.nav-pills .nav-link-danger {
  color: #ff4757 !important;
}

.app-container {
  min-height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.page-content {
  position: relative;
  font-size: 0.9rem;
  .btn {
    font-size: 0.9rem;
  }
  flex: 1 0 auto;
}

.custom-card-title {
  margin-bottom: 0;
}

.custom-card-title i,
.accordion h5 i {
  width: 21px;
  height: 21px;
}

.custom-card-title span,
.accordion h5 span {
  margin-left: 10px;
}

@media (min-width: 1200px) {
  .wide-container {
    max-width: 1600px !important;
  }
}

/* Listeleme GLOBAL */
.custom-header-bg {
  background: url("/img/background/commerce.png");
  background-size: 30%;
}
.action-btn {
  min-width: 35px;
}

.stack-btn {
  transition: 0.4s all;
  color: #3f51b5;
  position: relative;
  border: 1px solid #3f51b5;
}

.stack-btn span {
  position: absolute;
  transition: 0.4s all;

  width: 18px;
  height: 18px;
  line-height: 15px;
  top: -7px;
  left: -7px;

  font-weight: bold;
  font-size: 11px;
  border-radius: 30px;

  border: 2px solid #3f51b5;
  background-color: #3f51b5;
  color: #ffffff;
}

.stack-btn:hover {
  color: #ffffff;
  background-color: #3f51b5;
}

.stack-btn:hover span {
  color: #3f51b5;
  background-color: #ffffff;
}

.stack-btn.empty {
  color: #ced6e0;
  border-color: #ced6e0;
  background-color: transparent;
}

.stack-btn.empty span {
  border-color: #ced6e0;
  background-color: #ffffff;
  color: #ced6e0;
}

/* Listeleme GLOBAL */
.category-list-group {
  max-height: 250px;
  overflow: auto;
}

.category-list-group li {
  transition: 0.4s all;
}

.category-list-group li:hover {
  background-color: #ced6e0;
}

.feature-group-card {
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 2rem;
  transition: 0.4s all;
}

.feature-group-card .feature-title {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #6b6b6b;
  font-weight: 700;

  padding: 0 15px;
  position: absolute;
  top: -10px;
  left: 10px;
}

.feature-group-card:hover {
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.feature-group-card:hover .feature-remove {
  display: block;
}

.feature-group-card .feature-remove {
  color: #ff4757;
  text-decoration: none;
  background-color: #ffffff;

  padding: 0 15px;
  position: absolute;
  top: -10px;
  right: 10px;

  display: none;
}

.feature-group-card .feature-remove:hover {
  color: #bb2c38;
}

.accordion-overflow {
  max-height: 250px;
  overflow: auto;
}
</style>
